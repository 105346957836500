import React, { useState } from "react";
import {
  Checkbox,
  Spinner,
  makeStyles,
  shorthands,
  tokens,
  Text,
} from "@fluentui/react-components";
import { Controller, useForm } from "react-hook-form";
import { Grid } from "../common/grid/Grid";
import { GridItem } from "../common/grid/GridItem";
import { Btn } from "../common/input/Btn";
import { ControlledTextInput } from "../common/input/TextInput";
import { ContactSection, Message } from "../../graphql/schema";
import { Warning48Regular, Mail48Regular } from "@fluentui/react-icons";
import { BodyText } from "../common/text/BodyText";

const useStyles = makeStyles({
  wrapper: {
    height: "450px",
    "@media screen and (min-width: 600px)": {
      height: "380px",
    },
  },
  btn: {
    ...shorthands.margin(0, "auto"),
  },
  formGrid: {
    maxWidth: "800px",
    ...shorthands.margin(0, "auto"),
    ...shorthands.gap(tokens.spacingVerticalS, tokens.spacingVerticalS),
    "@media screen and (min-width: 600px)": {
      ...shorthands.gap(tokens.spacingVerticalS, tokens.spacingVerticalS),
    },
    "@media screen and (min-width: 800px)": {
      ...shorthands.gap(tokens.spacingVerticalS, tokens.spacingVerticalS),
    },
  },
  validationMessage: {
    position: "relative",
    top: "20%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});
type ContactFormProps = {
  contactSection: ContactSection;
};
export const ContactForm = ({ contactSection }: ContactFormProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [messageSent, setMessageSent] = useState<boolean>(false);

  const form = useForm<Message>();
  const { handleSubmit, control, reset } = form;
  const onSubmit = async (message: Message) => {
    if (message.policyApproved) {
      setLoading(true);
      fetch(`${process.env.REACT_APP_CMS_URL}/api/messages`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_CMS_JWT}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: message }),
      })
        .then((x) => setMessageSent(true))
        .catch((err) => {
          setError(err);
        })
        .finally(() => setLoading(false));
    }
  };
  const resetForm = () => {
    reset();
    setError(null);
    setMessageSent(false);
  };
  return (
    <section className={classes.wrapper}>
      {loading ? (
        <Spinner style={{ position: "relative", top: "30%" }} />
      ) : error || messageSent ? (
        <div className={classes.validationMessage}>
          {error ? <Warning48Regular /> : <Mail48Regular />}
          <BodyText align="center">
            {error
              ? contactSection.errorMessage
              : contactSection.validationMessage}
          </BodyText>
          <Text
            style={{ cursor: "pointer" }}
            italic
            underline
            align="center"
            onClick={() => {
              resetForm();
            }}
          >
            {contactSection.resetFormText}
          </Text>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid className={classes.formGrid}>
            <GridItem size="12/12">
              <ControlledTextInput
                required
                label={contactSection.nameLabel}
                name={"name"}
                control={control}
              />
            </GridItem>
            <GridItem size="6/12">
              <ControlledTextInput
                required
                label={contactSection.emailLabel}
                name={"email"}
                control={control}
                type="email"
              />
            </GridItem>

            <GridItem size="6/12">
              <ControlledTextInput
                label={contactSection.phoneLabel}
                name={"phone"}
                control={control}
                type="tel"
              />
            </GridItem>
            <GridItem size="12/12">
              <ControlledTextInput
                required
                label={contactSection.messageLabel}
                name={"message"}
                control={control}
                multiline
              />
            </GridItem>
            <GridItem size="12/12">
              <Controller
                name={"policyApproved"}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    size="large"
                    style={{ fontSize: tokens.fontSizeBase400 }}
                    label={contactSection.policyLabel}
                    required
                    checked={value ?? false}
                    onChange={onChange}
                  />
                )}
              />
            </GridItem>
            <GridItem size="12/12">
              <Btn className={classes.btn} type="submit">
                {contactSection.buttonText}
              </Btn>
            </GridItem>
          </Grid>
        </form>
      )}
    </section>
  );
};
