import React from "react";

import { Error } from "./pages/Error";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Start } from "./pages/Start";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Start />,
  },
  {
    path: "*",
    element: (
      <Error text={"Den här sidan finns inte"} error="404" linkToRoot={true} />
    ),
  },
]);
export const Router = () => {
  return <RouterProvider router={router} />;
};
