import React from "react";
import { useQuery } from "@apollo/client";
import { GET_COOKIEPOLICY, GET_PRIVACYPOLICY } from "../../graphql/queries";
import { Policy as IPolicy } from "../../graphql/schema";
import { generateHTMLFromRichText } from "../../helper/markdownHelper";
import {
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  makeStyles,
} from "@fluentui/react-components";
import { useSearchParams } from "react-router-dom";
import { Title } from "../common/text/Title";

const useStyles = makeStyles({
  surface: {
    maxWidth: "100vw",
    marginTop: "50px",
    "@media screen and (min-width: 800px)": {
      marginTop: "auto",
      maxWidth: "75vw",
    },
  },
});
export const Policy = () => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const policy = searchParams.get("policy");
  const { data, loading, error } = useQuery(
    policy === "cookies" ? GET_COOKIEPOLICY : GET_PRIVACYPOLICY
  );
  if (loading || error) return <></>;
  const policyData: IPolicy = data.policy.data.attributes;
  return (
    <Dialog
      modalType="non-modal"
      open={searchParams.get("policy") ? true : false}
      onOpenChange={(e, data) => {
        setSearchParams({});
      }}
    >
      <DialogSurface
        backdrop={<div aria-hidden={"true"} />}
        className={classes.surface}
      >
        <DialogBody>
          <DialogTitle>
            <Title as="h2">
              {policy === "cookies"
                ? policyData.cookiePolicyTitle
                : policyData.privacyPolicyTitle}
            </Title>
          </DialogTitle>
          <DialogContent>
            {generateHTMLFromRichText(
              policy === "cookies"
                ? policyData.cookiePolicy
                : policyData.privacyPolicy,
              true
            )}
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
