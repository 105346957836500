import { useMsal } from "@azure/msal-react";
import { Button, LargeTitle, makeStyles } from "@fluentui/react-components";
const useStyles = makeStyles({
  wrapper: {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
});

export const Login = () => {
  const classes = useStyles();
  const { instance } = useMsal();

  const initializeSignIn = () => {
    instance.loginRedirect();
  };
  return (
    <article>
      <div className={classes.wrapper}>
        <LargeTitle as="h2">Välkommen till 365Robust.se</LargeTitle>
        <div>
          <Button size="large" appearance="primary" onClick={initializeSignIn}>
            Logga in
          </Button>
        </div>
      </div>
    </article>
  );
};
