import { BodyText } from "../components/common/text/BodyText";
import {
  Subtitle1,
  Subtitle2,
  Title3,
  tokens,
} from "@fluentui/react-components";

interface TextNode {
  text: string;
  type: "text";
  bold?: boolean;
  underline?: boolean;
  italic?: boolean;
  strikethrough?: boolean;
  code?: boolean;
}

interface LinkNode {
  url: string;
  type: "link";
  children: TextNode[];
}

interface ListItemNode {
  type: "list-item";
  children: (TextNode | LinkNode)[];
}

interface ImageFormat {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: null | string;
  size: number;
  width: number;
  height: number;
}

interface ImageNode {
  type: "image";
  image: {
    ext: string;
    url: string;
    hash: string;
    mime: string;
    name: string;
    size: number;
    width: number;
    height: number;
    caption: string;
    formats: {
      large?: ImageFormat;
      small?: ImageFormat;
      medium?: ImageFormat;
      thumbnail?: ImageFormat;
    };
    provider: string;
    createdAt: string;
    updatedAt: string;
    previewUrl: null | string;
    alternativeText: string;
    provider_metadata: null | any;
  };
  children: TextNode[];
}

interface BlockNode {
  type: "heading" | "paragraph" | "list" | "quote";
  level?: number;
  format?: "unordered" | "ordered";
  children: (TextNode | LinkNode | ListItemNode | ImageNode)[];
}

type RichTextInput = BlockNode[];

export const generateHTMLFromRichText = (
  data: any,
  smallBodyText?: boolean
) => {
  const html: JSX.Element[] = [];
  const richText: RichTextInput = data;
  richText.forEach((x) => {
    switch (x.type) {
      case "heading":
        const heading = x.children[0] as TextNode;
        html.push(getHeading(x.level ?? 5, heading.text));
        break;
      case "paragraph":
        const paragraphs = x.children as TextNode[] | LinkNode[];
        if (paragraphs.length === 1) {
          paragraphs[0].type === "text"
            ? html.push(
                <BodyText
                  size={smallBodyText ? 400 : undefined}
                  semibold={paragraphs[0].bold}
                  italic={paragraphs[0].italic}
                  strikeThrough={paragraphs[0].strikethrough}
                  underline={paragraphs[0].underline}
                >
                  {paragraphs[0].text}
                </BodyText>
              )
            : html.push(
                <a href={paragraphs[0].url}>
                  <BodyText
                    size={smallBodyText ? 400 : undefined}
                    italic={true}
                  >
                    {paragraphs[0].children[0].text}
                  </BodyText>
                </a>
              );
        } else
          html.push(
            <p>
              {paragraphs.map((p) => {
                if (p.type === "text")
                  return (
                    <BodyText
                      size={smallBodyText ? 400 : undefined}
                      as="span"
                      block={false}
                      semibold={p.bold}
                      italic={p.italic}
                      strikeThrough={p.strikethrough}
                      underline={p.underline}
                    >
                      {p.text}
                    </BodyText>
                  );
                else
                  return (
                    <a href={p.url}>
                      <BodyText
                        size={smallBodyText ? 400 : undefined}
                        italic={true}
                      >
                        {p.children[0].text}
                      </BodyText>
                    </a>
                  );
              })}
            </p>
          );
        break;
      case "list":
        const listElements: JSX.Element[] = [];
        const listNodes = x.children as ListItemNode[];
        listNodes.forEach((n) => {
          const node = n.children[0] as TextNode;
          listElements.push(
            <li
              style={{
                fontSize:
                  window.screen.width > 600
                    ? tokens.fontSizeBase500
                    : tokens.fontSizeBase500,
              }}
            >
              <BodyText
                size={smallBodyText ? 400 : undefined}
                semibold={node.bold}
                italic={node.italic}
              >
                {node.text}
              </BodyText>
            </li>
          );
        });
        x.format === "unordered"
          ? html.push(<ul>{listElements}</ul>)
          : html.push(<ol>{listElements}</ol>);
    }
  });
  return html;
};

const getHeading = (level: number, text: string) => {
  switch (level) {
    case 1:
    case 2:
      return (
        <Title3 as={"h2"} block>
          {text}
        </Title3>
      );

    case 3:
      return (
        <Subtitle1 as={"h3"} block>
          {text}
        </Subtitle1>
      );
    case 4:
      return (
        <Subtitle2 as={"h4"} block>
          {text}
        </Subtitle2>
      );
    case 5:
    default:
      return (
        <Subtitle2 as={"h5"} block>
          {text}
        </Subtitle2>
      );
  }
};
