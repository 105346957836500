import React from "react";
import { Text } from "@fluentui/react-components";

type BodyTextProps = {
  children: string | string[];
  align?: "center" | "end" | "justify" | "start";
  onClick?: () => void;
  block?: boolean;
  className?: string;
  italic?: boolean;
  semibold?: boolean;
  size?: 600 | 100 | 200 | 300 | 400 | 500 | 700 | 800 | 900 | 1000;
  as?: "p" | "span";
  strikeThrough?: boolean;
  underline?: boolean;
};
export const BodyText = ({
  children,
  align,
  onClick,
  block,
  className,
  italic,
  semibold,
  size,
  strikeThrough,
  underline,
}: BodyTextProps) => {
  return (
    <Text
      weight={semibold ? "semibold" : "regular"}
      className={className ?? ""}
      size={size ? size : window.screen.width > 600 ? 500 : 400}
      as="p"
      align={align ?? "start"}
      block={block === undefined || block ? true : false}
      onClick={onClick}
      italic={italic ?? false}
      strikethrough={strikeThrough ?? false}
      underline={underline ?? false}
    >
      {children}
    </Text>
  );
};
