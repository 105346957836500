import React from "react";
import {
  Card,
  CardHeader,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";

type CardProps = {
  header: JSX.Element;
  description: JSX.Element | JSX.Element[];
  className?: string;
  extraPadding?: boolean;
};
const useStyles = makeStyles({
  largePadding: {
    ...shorthands.padding(tokens.spacingHorizontalL),
    "@media screen and (min-width: 600px)": {
      ...shorthands.padding(tokens.spacingHorizontalXXXL),
    },
    "@media screen and (min-width: 800px)": {
      ...shorthands.margin(`calc(${tokens.spacingHorizontalXXXL} * 2)`),
    },
  },
  mediumPadding: {
    ...shorthands.padding(tokens.spacingHorizontalL),
    "@media screen and (min-width: 600px)": {
      ...shorthands.padding(tokens.spacingHorizontalXL),
    },
  },

  card: {
    backgroundColor: tokens.colorPaletteMarigoldBackground2,
    boxShadow: tokens.shadow16,
  },
});
export const TextCard = ({
  header,
  description,
  className,
  extraPadding,
}: CardProps) => {
  const classes = useStyles();
  return (
    <Card
      className={mergeClasses(
        classes.card,
        extraPadding ? classes.largePadding : classes.mediumPadding,
        className ?? ""
      )}
      style={{ borderRadius: tokens.borderRadiusXLarge }}
    >
      <CardHeader header={header} />
      <div>{description}</div>
    </Card>
  );
};
