import React from "react";
import { Link, makeStyles, tokens } from "@fluentui/react-components";
import { Title } from "../components/common/text/Title";
import { BodyText } from "../components/common/text/BodyText";

type ErrorProps = {
  error: string;
  text?: string;
  linkToRoot?: boolean;
};

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: tokens.colorNeutralBackground3,
    minHeight: "100vh",
    paddingTop: tokens.spacingVerticalXXXL,
  },
});

export const Error = ({ error, text, linkToRoot }: ErrorProps) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Title align="center" as="h2">
        Oops!
      </Title>
      <BodyText align="center">
        {text ?? "Hoppsan! Någonting gick fel"}
      </BodyText>
      <BodyText align="center">Felmeddelande: {error}</BodyText>
      {linkToRoot && (
        <Link as="a" href="/">
          <BodyText italic={true} align="center">
            Gå till start
          </BodyText>
        </Link>
      )}
    </div>
  );
};
