import {
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import React from "react";

type PageSectionProps = {
  className?: string;
  id?: string;
  children: JSX.Element | JSX.Element[] | string | string[];
};
const useStyles = makeStyles({
  section: {
    ...shorthands.padding(tokens.spacingHorizontalXL, tokens.spacingVerticalXL),

    "@media screen and (min-width: 600px)": {
      ...shorthands.padding(
        tokens.spacingHorizontalXXXL,
        tokens.spacingVerticalXXXL
      ),
    },
    "@media screen and (min-width: 1600px)": {
      ...shorthands.padding(tokens.spacingHorizontalXXXL, 0),
      maxWidth: "1400px",
      ...shorthands.margin(0, "auto"),
    },
  },
});
export const PageSection = ({ className, id, children }: PageSectionProps) => {
  const classes = useStyles();
  return (
    <article id={id} className={mergeClasses(classes.section, className ?? "")}>
      {children}
    </article>
  );
};
