import React from "react";
import { useQuery } from "@apollo/client";
import { Title } from "../common/text/Title";
import { ServiceSection } from "../../graphql/schema";
import { TextCard } from "../common/card/Card";
import { Grid } from "../common/grid/Grid";
import { GridItem } from "../common/grid/GridItem";
import { PageSection } from "../common/pageSection/PageSection";
import { makeStyles, tokens } from "@fluentui/react-components";
import { GET_SERVICES } from "../../graphql/queries";
import { BodyText } from "../common/text/BodyText";

const useStyles = makeStyles({
  content: {
    marginTop: tokens.spacingVerticalXXXL,
  },
  height100: {
    height: "100%",
  },
});
export const Services = () => {
  const classes = useStyles();
  const { data, error, loading } = useQuery(GET_SERVICES);
  if (loading || error || !data.serviceSection.data) return <></>;
  const serviceSection: ServiceSection = data.serviceSection.data?.attributes;
  const numberOfServices = serviceSection?.services
    ? serviceSection.services?.data.length
    : 0;
  const restServices = numberOfServices % 3;
  return (
    <div>
      <PageSection>
        <Title align="center" as="h2">
          {serviceSection.title}
        </Title>
        <Grid className={classes.content}>
          {serviceSection.services ? (
            serviceSection.services?.data.map((x, i) => (
              <GridItem
                key={i}
                size={
                  i < numberOfServices - restServices &&
                  window.screen.width > 800
                    ? "4/12"
                    : restServices === 2 || window.screen.width <= 800
                    ? "6/12"
                    : "12/12"
                }
              >
                <TextCard
                  className={classes.height100}
                  header={<Title as="h3">{x.attributes?.title ?? ""}</Title>}
                  description={
                    <BodyText>{x.attributes?.description ?? ""}</BodyText>
                  }
                />
              </GridItem>
            ))
          ) : (
            <></>
          )}
        </Grid>
      </PageSection>
    </div>
  );
};
