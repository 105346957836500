import {
  Link,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import React from "react";
import { useQuery } from "@apollo/client";
import { GET_FOOTER } from "../../graphql/queries";
import { Title } from "../common/text/Title";
import Img from "../common/image/Img";
import { PageSection } from "../common/pageSection/PageSection";
import { Grid } from "../common/grid/Grid";
import { GridItem } from "../common/grid/GridItem";
import { BodyText } from "../common/text/BodyText";
import { Footer as IFooter, Policy } from "../../graphql/schema";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles({
  footer: {
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralBackground3,
  },
  footerContent: {
    maxWidth: "1400px",
    ...shorthands.margin(0, "auto"),
  },

  logoChild: {
    order: 1,
    "@media screen and (min-width: 800px)": {
      justifySelf: "center",
      order: "initial",
      "& p": {
        textAlign: "center",
      },
    },
  },
  logo: {
    height: "80px",
    "@media screen and (min-width: 600px)": {
      height: "110px",
    },
  },

  clickableText: {
    cursor: "pointer",
  },
  linkWrapper: {
    marginTop: tokens.spacingHorizontalXL,
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingHorizontalXL),
  },
});

export const Footer = () => {
  const { data, loading, error } = useQuery(GET_FOOTER);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const classes = useStyles();
  if (loading || error || !data.footer.data || !data.policy.data) return <></>;
  const footerData: IFooter = data.footer.data.attributes;
  const policy: Policy = data.policy.data.attributes;
  return (
    <footer className={classes.footer}>
      <PageSection>
        <Grid className={classes.footerContent}>
          <GridItem size="4/12">
            <Title as="h4">{footerData.contact?.title ?? ""}</Title>
            <section className={classes.linkWrapper}>
              <div>
                <BodyText block={false}>
                  {`${footerData.contact.phoneLabel}: `}
                </BodyText>

                <Link
                  style={{ color: "white" }}
                  as="a"
                  aria-label={`call 365Robust at ${footerData.contact.phone}`}
                  href={`tel: ${footerData.contact.phone}`}
                  rel="noreferrer"
                >
                  <BodyText className={classes.clickableText} block={false}>
                    {footerData.contact.phone ?? ""}
                  </BodyText>
                </Link>
              </div>
              <div>
                <BodyText block={false}>
                  {`${footerData.contact.emailLabel}: `}
                </BodyText>
                <Link
                  style={{ color: "white" }}
                  as="a"
                  aria-label={`email 365Robust at ${footerData.contact.email}`}
                  href={`mailto:${footerData.contact.email}?subject=Kontakt med 365Robust`}
                  rel="noreferrer"
                >
                  <BodyText className={classes.clickableText} block={false}>
                    {footerData.contact.email ?? ""}
                  </BodyText>
                </Link>
              </div>
              <div>
                <BodyText block={false}>
                  {`${footerData.contact.organizationNumberLabel}: `}
                </BodyText>
                <BodyText block={false}>
                  {footerData.contact.organizationNumber ?? ""}
                </BodyText>
              </div>
            </section>
          </GridItem>
          <GridItem size="4/12" className={classes.logoChild}>
            <Img
              className={classes.logo}
              alt={footerData?.logo.data?.attributes?.alternativeText ?? "logo"}
              src={footerData?.logo.data?.attributes?.url ?? ""}
            />
            <BodyText>{footerData?.copyright ?? ""}</BodyText>
          </GridItem>
          <GridItem size="4/12">
            <Title as="h4">{footerData?.policiesTitle ?? ""}</Title>

            <BodyText
              className={classes.clickableText}
              block
              onClick={() => setSearchParams({ policy: "cookies" })}
            >
              {policy.cookiePolicyTitle ?? ""}
            </BodyText>

            <BodyText
              className={classes.clickableText}
              block
              onClick={() => setSearchParams({ policy: "privacy" })}
            >
              {policy.privacyPolicyTitle ?? ""}
            </BodyText>
          </GridItem>
        </Grid>
      </PageSection>
    </footer>
  );
};
