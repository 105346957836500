import React from "react";
import { App } from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { msalConfig } from "./auth/authConfig";
import { Login } from "./pages/Login";
import { BrandVariants, FluentProvider, Theme, createLightTheme } from "@fluentui/react-components";
export const msalInstance = new PublicClientApplication(msalConfig);

export const Root = () => {
  const Robust: BrandVariants = {
    10: "#020304",
    20: "#12171E",
    30: "#1B2634",
    40: "#213245",
    50: "#2B3F56",
    60: "#394B61",
    70: "#47586C",
    80: "#566578",
    90: "#657284",
    100: "#748090",
    110: "#838E9D",
    120: "#939CA9",
    130: "#A3ABB6",
    140: "#B3B9C3",
    150: "#C3C8D0",
    160: "#D4D7DD",
  };

  const lightTheme: Theme = {
    ...createLightTheme(Robust),
    fontFamilyBase: "Segoe UI",
    colorPaletteMarigoldBackground1: "#FFFCF5",
    colorPaletteMarigoldBackground2: "#EBDDC3",
    colorPaletteMarigoldBorderActive: "rgb(255, 252, 245, 0.9)",
    colorPaletteMarigoldBackground3: "#A39579",
    colorPalettePlatinumBackground2: "#687684",

    colorNeutralForeground1: "#14191C",

    colorBrandBackground: "#263B52",
  };
  const getComponent = () => {
    switch (window.location.host) {
      case "test.365robust.se":
        return (
          <FluentProvider theme={lightTheme}>
            <MsalProvider instance={msalInstance}>
              <AuthenticatedTemplate>
                <App />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Login />
              </UnauthenticatedTemplate>
            </MsalProvider>
          </FluentProvider>
        );
      default:
        return (
          <FluentProvider theme={lightTheme}>
            <App />
          </FluentProvider>
        );
    }
  };
  return getComponent();
};
