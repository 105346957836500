import { useQuery } from "@apollo/client";
import React, { Fragment } from "react";
import { GET_ABOUT } from "../../graphql/queries";
import { PageSection } from "../common/pageSection/PageSection";
import {
  Divider,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { AboutSection } from "../../graphql/schema";
import { Title } from "../common/text/Title";
import Img from "../common/image/Img";
import { generateHTMLFromRichText } from "../../helper/markdownHelper";

const useStyles = makeStyles({
  flex: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingHorizontalXXXL),
    "@media screen and (min-width: 1000px)": {
      flexDirection: "row",
      ...shorthands.gap(`calc(${tokens.spacingHorizontalXXXL} * 2)`),
      ...shorthands.padding(`calc(${tokens.spacingHorizontalXXXL} * 2)`),
    },
  },
  flexChild: {
    "@media screen and (min-width: 1000px)": {
      width: "50%",
    },
  },
  flex100: {
    "@media screen and (min-width: 1000px)": {
      width: "100%",
    },
  },
  img: {
    maxHeight: "400px",
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingHorizontalXL),
  },
  mtb: {
    ...shorthands.margin(tokens.spacingHorizontalXL, 0),
    "@media screen and (min-width: 1000px)": {
      ...shorthands.margin(tokens.spacingHorizontalXXXL, 0),
    },
  },
  reverse: {
    "@media screen and (min-width: 1000px)": {
      flexDirection: "row-reverse",
    },
  },
});
export const About = () => {
  const classes = useStyles();
  const { data, loading, error } = useQuery(GET_ABOUT);
  if (loading || error || !data.aboutSection.data) return <></>;
  const aboutData: AboutSection = data.aboutSection.data.attributes;
  let prevImgSectionWasReverse = false;
  return (
    <PageSection>
      {aboutData.abouts?.data ? (
        aboutData.abouts?.data.map((x, i) => {
          const currentSectionHasImage = x.attributes?.image?.data !== null;
          const isReverse = prevImgSectionWasReverse && currentSectionHasImage;

          prevImgSectionWasReverse = !isReverse;
          return (
            <Fragment key={i}>
              {i !== 0 && <Divider className={classes.mtb} />}
              <section
                className={mergeClasses(
                  classes.flex,
                  isReverse ? classes.reverse : ""
                )}
              >
                {x.attributes?.image?.data && (
                  <span className={classes.flexChild}>
                    <Img
                      className={classes.img}
                      alt={
                        x.attributes.image.data?.attributes?.alternativeText ??
                        `picture to ${x.attributes.title}`
                      }
                      src={x.attributes.image.data?.attributes?.url}
                    />
                  </span>
                )}

                <span
                  className={mergeClasses(
                    classes.flexChild,
                    !x.attributes?.image?.data ? classes.flex100 : ""
                  )}
                >
                  <Title as="h2">{x.attributes?.title ?? ""}</Title>
                  {generateHTMLFromRichText(x.attributes?.description)}
                </span>
              </section>
            </Fragment>
          );
        })
      ) : (
        <></>
      )}
    </PageSection>
  );
};
