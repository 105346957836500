import React from "react";
import { About } from "../components/about/About";
import { Contact } from "../components/contact/ContactSection";
import { Hero } from "../components/hero/Hero";
import { Services } from "../components/services/Services";
import { Page } from "./Page";
import { useQuery } from "@apollo/client/react";
import { GET_HERO } from "../graphql/queries";
import { Error } from "./Error";

export const Start = () => {
  const { data, loading, error } = useQuery(GET_HERO);
  if (loading) return <></>;
  if (error || !data.heroSection.data)
    return (
      <Error error={error ? error.message : "Sidan hämtades inte som tänkt"} />
    );

  return (
    <Page>
      <Hero data={data.heroSection?.data?.attributes} />
      <Services />
      <About />
      <Contact />
    </Page>
  );
};
