import React from "react";
import { Image, makeStyles, mergeClasses } from "@fluentui/react-components";

type imgProps = {
  alt: string;
  src: string | undefined;
  className?: string;
  onClick?: () => void;
};
const useStyles = makeStyles({
  img: {
    maxWidth: "100%",
  },
});
const Img = ({ alt, src, className, onClick }: imgProps) => {
  const classes = useStyles();
  return (
    <Image
      className={mergeClasses(classes.img, className ?? "")}
      alt={alt}
      src={`${src ?? ""}`}
      onClick={onClick}
    />
  );
};

export default Img;
