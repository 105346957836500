import React from "react";
import {
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";

type GridProps = {
  children: JSX.Element | JSX.Element[];
  className?: string;
};

const useStyles = makeStyles({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",

    ...shorthands.gap(tokens.spacingVerticalXL, tokens.spacingVerticalXL),
    "@media screen and (min-width: 600px)": {
      ...shorthands.gap(
        tokens.spacingVerticalXXXL,
        tokens.spacingHorizontalXXXL
      ),
    },
    "@media screen and (min-width: 800px)": {
      ...shorthands.gap(
        `calc(${tokens.spacingVerticalXXL} * 2)`,
        `calc(${tokens.spacingHorizontalXXL} * 2)`
      ),
    },
  },
});
export const Grid = ({ children, className }: GridProps) => {
  const classes = useStyles();
  return (
    <section className={mergeClasses(classes.grid, className)}>
      {children}
    </section>
  );
};
