import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n/i18n";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import "./index.css";
import { Root } from "./Root";

const cmsLink = createHttpLink({
  uri: `${process.env.REACT_APP_CMS_URL}/graphql`,
});

const auth = setContext((_, { headers }) => {
  const token = process.env.REACT_APP_CMS_JWT;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const client = new ApolloClient({
  link: auth.concat(cmsLink),
  cache: new InMemoryCache(),
});
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Root />
    </ApolloProvider>
  </React.StrictMode>
);
