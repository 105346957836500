import { gql } from "@apollo/client";

export const GET_SERVICES = gql`
  query serviceSection {
    serviceSection {
      data {
        attributes {
          title
          services {
            data {
              attributes {
                title
                description
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_INIT = gql`
  query init {
    header {
      data {
        attributes {
          loginText
          contactText
          logo {
            data {
              attributes {
                alternativeText
                url
              }
            }
          }
        }
      }
    }
    cookieConsent {
      data {
        attributes {
          title
          text
          readMoreText
          approveButtonText
          declineButtonText
        }
      }
    }
  }
`;
export const GET_HERO = gql`
  query heroSection {
    heroSection {
      data {
        attributes {
          title
          subHeading
          description
          image {
            data {
              attributes {
                alternativeText
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_FOOTER = gql`
  query getFooter {
    footer {
      data {
        attributes {
          logo {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          copyright
          policiesTitle
          contact {
            title
            email
            emailLabel
            phone
            phoneLabel
            organizationNumber
            organizationNumberLabel
          }
        }
      }
    }
    policy {
      data {
        attributes {
          privacyPolicyTitle
          cookiePolicyTitle
        }
      }
    }
  }
`;

export const GET_ABOUT = gql`
  query getAbout {
    aboutSection {
      data {
        attributes {
          abouts {
            data {
              attributes {
                title
                description
                image {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CONTACTSECTION = gql`
  query getContact {
    contactSection {
      data {
        attributes {
          title
          subHeading
          buttonText
          nameLabel
          emailLabel
          phoneLabel
          messageLabel
          policyLabel
          errorMessage
          validationMessage
          resetFormText
        }
      }
    }
  }
`;

export const GET_COOKIEPOLICY = gql`
  query getContact {
    policy {
      data {
        attributes {
          cookiePolicy
          cookiePolicyTitle
        }
      }
    }
  }
`;
export const GET_PRIVACYPOLICY = gql`
  query getContact {
    policy {
      data {
        attributes {
          privacyPolicy
          privacyPolicyTitle
        }
      }
    }
  }
`;
