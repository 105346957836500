import React from "react";
import {
  Title1,
  Title2,
  Title3,
  Subtitle1,
  Subtitle2,
  makeStyles,
  tokens,
  shorthands,
  mergeClasses,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  bold: {
    fontWeight: tokens.fontWeightSemibold,
    ...shorthands.margin(tokens.spacingHorizontalS, 0),
  },
});
type TitleProps = {
  as: "h1" | "h2" | "h3" | "h4" | "h5";
  children: JSX.Element | string;
  className?: string;
  align?: "center" | "end" | "start" | "justify";
};
export const Title = ({ as, children, className, align }: TitleProps) => {
  const classes = useStyles();
  switch (as) {
    case "h1":
      return (
        <Title1
          align={align}
          as={as}
          block
          className={mergeClasses(classes.bold, className ?? "")}
        >
          {children}
        </Title1>
      );
    case "h2":
      return (
        <Title2
          align={align}
          as={as}
          block
          className={mergeClasses(classes.bold, className ?? "")}
        >
          {children}
        </Title2>
      );
    case "h3":
      return (
        <Title3
          align={align}
          as={as}
          block
          className={mergeClasses(classes.bold, className ?? "")}
        >
          {children}
        </Title3>
      );
    case "h4":
      return (
        <Subtitle1
          align={align}
          as={as}
          block
          className={mergeClasses(classes.bold, className ?? "")}
        >
          {children}
        </Subtitle1>
      );
    case "h5":
      return (
        <Subtitle2
          align={align}
          as={as}
          block
          className={mergeClasses(classes.bold, className ?? "")}
        >
          {children}
        </Subtitle2>
      );
  }
};
