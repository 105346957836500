import React from "react";
import { useQuery } from "@apollo/client";
import { Spinner, makeStyles, tokens } from "@fluentui/react-components";
import { GET_INIT } from "../graphql/queries";
import { Footer } from "../components/footer/Footer";
import { Error } from "./Error";
import { Nav } from "../components/nav/Nav";
import { CookieConsentBar } from "../components/cookies/Cookies";
import { Policy } from "../components/policy/Policy";
const useStyles = makeStyles({
  appWrapper: {
    backgroundColor: tokens.colorNeutralBackground4,
    minHeight: "100vh",
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
});
type PageProps = {
  children: JSX.Element | JSX.Element[] | string | string[];
};
export const Page = ({ children }: PageProps) => {
  const classes = useStyles();
  const { data, loading, error } = useQuery(GET_INIT);

  if (loading)
    return (
      <div className={classes.appWrapper}>
        <Spinner className={classes.spinner} />
      </div>
    );
  if (error) return <Error error={error.message} />;
  return (
    <>
      <CookieConsentBar data={data.cookieConsent?.data?.attributes} />
      <div className={classes.appWrapper}>
        <Nav data={data.header} />
        <main style={{ minHeight: "80vh" }}>{children}</main>
        <Footer />
      </div>
      <Policy />
    </>
  );
};
