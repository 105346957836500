import React from "react";
import { PageSection } from "../common/pageSection/PageSection";
import { useQuery } from "@apollo/client";
import { GET_CONTACTSECTION } from "../../graphql/queries";
import { Title } from "../common/text/Title";
import { ContactSection } from "../../graphql/schema";
import { makeStyles, tokens, shorthands } from "@fluentui/react-components";
import { BodyText } from "../common/text/BodyText";
import { ContactForm } from "./ContactForm";

const useStyles = makeStyles({
  bgColor: {
    backgroundColor: tokens.colorPaletteMarigoldBackground2,
    boxShadow: tokens.shadow28,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    ...shorthands.gap(tokens.spacingVerticalS),
  },
});
export const Contact = (): JSX.Element => {
  const classes = useStyles();
  const { data, loading, error } = useQuery(GET_CONTACTSECTION);
  if (loading || error || !data.contactSection.data) return <></>;
  const contactSection: ContactSection = data.contactSection.data.attributes;

  return (
    <div className={classes.bgColor}>
      <PageSection id="contact" className={classes.wrapper}>
        <Title align="center" as="h2">
          {contactSection.title}
        </Title>
        <BodyText align="center">{contactSection.subHeading ?? ""}</BodyText>
        <ContactForm contactSection={contactSection} />
      </PageSection>
    </div>
  );
};
