import { Button, tokens } from "@fluentui/react-components";
import React from "react";

type BtnProps = {
  children: string;
  onClick?: () => void;
  appearance?: "secondary" | "primary" | "outline" | "subtle" | "transparent";
  type?: "button" | "submit" | "reset";
  className?: string;
  size?: "small" | "large" | "medium";
};
export const Btn = ({
  appearance,
  type,
  children,
  className,
  onClick,
  size,
}: BtnProps) => {
  return (
    <Button
      style={{
        fontWeight: tokens.fontWeightBold,
        fontSize: tokens.fontSizeBase400,
      }}
      size={size ?? "medium"}
      className={className ?? ""}
      as="button"
      appearance={appearance ?? "secondary"}
      type={type ?? "button"}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
