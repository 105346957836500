import React from "react";
import {
  makeStyles,
  mergeClasses,
  shorthands,
} from "@fluentui/react-components";

type GridProps = {
  children?: JSX.Element | JSX.Element[];
  size:
    | "1/12"
    | "2/12"
    | "3/12"
    | "4/12"
    | "5/12"
    | "6/12"
    | "7/12"
    | "8/12"
    | "9/12"
    | "10/12"
    | "11/12"
    | "12/12";
  className?: string;
};
const useStyles = makeStyles({
  oneTwelfth: {
    ...shorthands.gridArea("auto", "auto", "auto", "span 12"),
    "@media screen and (min-width: 600px)": {
      ...shorthands.gridArea("auto", "auto", "auto", "span 1"),
    },
  },
  twoTwelfth: {
    ...shorthands.gridArea("auto", "auto", "auto", "span 12"),
    "@media screen and (min-width: 600px)": {
      ...shorthands.gridArea("auto", "auto", "auto", "span 2"),
    },
  },
  threeTwelfth: {
    ...shorthands.gridArea("auto", "auto", "auto", "span 12"),
    "@media screen and (min-width: 600px)": {
      ...shorthands.gridArea("auto", "auto", "auto", "span 3"),
    },
  },
  fourTwelfth: {
    ...shorthands.gridArea("auto", "auto", "auto", "span 12"),
    "@media screen and (min-width: 600px)": {
      ...shorthands.gridArea("auto", "auto", "auto", "span 6"),
    },
    "@media screen and (min-width: 800px)": {
      ...shorthands.gridArea("auto", "auto", "auto", "span 4"),
    },
  },
  fiveTwelfth: {
    ...shorthands.gridArea("auto", "auto", "auto", "span 12"),
    "@media screen and (min-width: 600px)": {
      ...shorthands.gridArea("auto", "auto", "auto", "span 5"),
    },
  },
  sixTwelfth: {
    ...shorthands.gridArea("auto", "auto", "auto", "span 12"),
    "@media screen and (min-width: 600px)": {
      ...shorthands.gridArea("auto", "auto", "auto", "span 6"),
    },
  },
  sevenTwelfth: {
    ...shorthands.gridArea("auto", "auto", "auto", "span 12"),
    "@media screen and (min-width: 600px)": {
      ...shorthands.gridArea("auto", "auto", "auto", "span 7"),
    },
  },
  eightTwelfth: {
    ...shorthands.gridArea("auto", "auto", "auto", "span 12"),
    "@media screen and (min-width: 600px)": {
      ...shorthands.gridArea("auto", "auto", "auto", "span 8"),
    },
  },
  nineTwelfth: {
    ...shorthands.gridArea("auto", "auto", "auto", "span 12"),
    "@media screen and (min-width: 600px)": {
      ...shorthands.gridArea("auto", "auto", "auto", "span 9"),
    },
  },
  tenTwelfth: {
    ...shorthands.gridArea("auto", "auto", "auto", "span 12"),
    "@media screen and (min-width: 600px)": {
      ...shorthands.gridArea("auto", "auto", "auto", "span 10"),
    },
  },
  elevenTwelfth: {
    ...shorthands.gridArea("auto", "auto", "auto", "span 12"),
    "@media screen and (min-width: 600px)": {
      ...shorthands.gridArea("auto", "auto", "auto", "span 11"),
    },
  },
  tewlweTwelfth: {
    ...shorthands.gridArea("auto", "auto", "auto", "span 12"),
  },
});
export const GridItem = ({
  children,
  size,
  className,
}: GridProps): JSX.Element => {
  const getClass = () => {
    switch (size) {
      case "1/12":
        return classes.oneTwelfth;
      case "2/12":
        return classes.twoTwelfth;
      case "3/12":
        return classes.threeTwelfth;
      case "4/12":
        return classes.fourTwelfth;
      case "5/12":
        return classes.fiveTwelfth;
      case "6/12":
        return classes.sixTwelfth;
      case "7/12":
        return classes.sevenTwelfth;
      case "8/12":
        return classes.eightTwelfth;
      case "9/12":
        return classes.nineTwelfth;
      case "10/12":
        return classes.tenTwelfth;
      case "11/12":
        return classes.elevenTwelfth;
      case "12/12":
      default:
        return classes.tewlweTwelfth;
    }
  };
  const classes = useStyles();
  return (
    <section className={mergeClasses(getClass(), className)}>
      {children}
    </section>
  );
};
