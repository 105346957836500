import {
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import React from "react";
import { HeaderEntityResponse } from "../../graphql/schema";
import Img from "../common/image/Img";
import { Btn } from "../common/input/Btn";
import { useNavigate } from "react-router-dom";
const scrollToContact = () => {
  const aboutElement = document.getElementById("contact");
  if (aboutElement) {
    aboutElement.scrollIntoView({ behavior: "smooth" });
  }
};
const useStyles = makeStyles({
  nav: {
    height: "50px",
    backgroundColor: tokens.colorBrandBackground,
    boxShadow: tokens.shadow16,
    position: "sticky",
    top: 0,
    zIndex: 2,
  },
  navContent: {
    ...shorthands.padding(0, tokens.spacingVerticalXL),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media screen and (min-width: 600px)": {
      ...shorthands.padding(0, tokens.spacingVerticalXXXL),
      justifyContent: "end",
      height: "55px",
    },
  },
  image: {
    height: "50px",
    cursor: "pointer",
    "@media screen and (min-width: 600px)": {
      height: "50px",
      position: "absolute",
      top: 0,
      left: "66px",
    },
  },
  hiddenOnPhone: {
    display: "none",
    "@media screen and (min-width: 600px)": {
      display: "block",
    },
  },
  showOnPhone: {
    "@media screen and (min-width: 600px)": {
      display: "none",
    },
  },
});
type NavProps = {
  data: HeaderEntityResponse;
  contentClassName?: string;
};
export const Nav = ({ data, contentClassName }: NavProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <nav className={classes.nav}>
      <div className={mergeClasses(classes.navContent, contentClassName ?? "")}>
        <Img
          onClick={() => navigate("/")}
          className={classes.image}
          alt={
            data.data?.attributes?.logo.data?.attributes?.alternativeText ?? ""
          }
          src={data.data?.attributes?.logo.data?.attributes?.url}
        />
        <span>
          <Btn appearance="primary" onClick={() => scrollToContact()}>
            {data.data?.attributes?.contactText ?? ""}
          </Btn>

          {/* <Btn
              className={classes.showOnPhone}
              appearance="transparent"
              icon={<Person32Regular />}
            /> */}
        </span>
        {/* <Button className={classes.hiddenOnPhone} appearance="primary">
            {data.data?.attributes?.loginText}
          </Button> */}
      </div>
    </nav>
  );
};
