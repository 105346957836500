import React from "react";
import {
  Field,
  Input,
  Textarea,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { Control, Controller } from "react-hook-form";
const useStyles = makeStyles({
  weightRegular: {
    "& label": {
      fontWeight: tokens.fontWeightRegular,
    },
  },
});
type ControlledTextInputProps = {
  name: any;
  control: Control<any>;
  label: string;
  disabled?: boolean;
  multiline?: boolean;
  required?: boolean;
  rows?: number;
  type?:
    | "number"
    | "search"
    | "time"
    | "text"
    | "email"
    | "password"
    | "tel"
    | "url"
    | "date"
    | "datetime-local"
    | "month"
    | "week";
};
export const ControlledTextInput = ({
  name,
  control,
  disabled,
  label,
  multiline,
  required,
  rows,
  type,
}: ControlledTextInputProps) => {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Field
          className={classes.weightRegular}
          label={label}
          required={required ?? false}
          size="large"
        >
          {multiline ? (
            <Textarea
              rows={rows}
              value={value ?? ""}
              onChange={onChange}
              disabled={disabled ?? false}
              size="large"
            />
          ) : (
            <Input
              size="large"
              // className={classes.fontSize}
              type={type}
              value={value ?? ""}
              onChange={onChange}
              disabled={disabled ?? false}
            />
          )}
        </Field>
      )}
    />
  );
};
