import React, { useEffect } from "react";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import { CookieConsent as ICookieConsent } from "../../graphql/schema";
import { Title } from "../common/text/Title";
import {
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { BodyText } from "../common/text/BodyText";
import ReactGA from "react-ga4";
import { useSearchParams } from "react-router-dom";
type CookieProps = {
  data: ICookieConsent;
};
const useStyles = makeStyles({
  container: {
    backgroundColor: `${tokens.colorPaletteMarigoldBackground1} !important`,
    boxShadow: tokens.shadow64,
    color: `${tokens.colorNeutralForeground1} !important`,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingVerticalXXXL),
    "@media screen and (min-width: 600px)": {
      justifyContent: "space-around",
      flexDirection: "row",

      alignItems: "center",
    },
  },
  pointer: { cursor: "pointer" },
  btnWrapper: {
    ...shorthands.padding(tokens.spacingHorizontalL),
    ...shorthands.margin("auto", 0),
    display: "flex",
    ...shorthands.gap(tokens.spacingHorizontalXXXL),
    "@media screen and (min-width: 600px)": {
      alignItems: "end",
    },
  },
  btn: {
    cursor: "pointer",
    minWidth: "96px",
    ...shorthands.padding(tokens.spacingHorizontalS, tokens.spacingHorizontalL),
    ...shorthands.borderColor(tokens.colorNeutralStrokeAccessible),
    ...shorthands.borderWidth(tokens.strokeWidthThin),
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
    backgroundColor: tokens.colorNeutralBackground1,
    color: tokens.colorNeutralForeground1,
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightSemibold,
  },
  primaryBtn: {
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralForegroundOnBrand,

    "& :hover": {
      backgroundColor: tokens.colorBrandBackgroundHover,
    },
  },
});
export const CookieConsentBar = ({ data }: CookieProps) => {
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const handleDeclineCookie = (): void => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };
  const handleAcceptCookie = (): void => {
    const GA_ID = process.env.REACT_APP_GA_ID;
    ReactGA.initialize(`${GA_ID}`);
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);
  return (
    <CookieConsent
      overlay
      buttonWrapperClasses={classes.btnWrapper}
      containerClasses={classes.container}
      enableDeclineButton
      contentClasses={classes.content}
      disableButtonStyles
      buttonClasses={mergeClasses(classes.btn, classes.primaryBtn)}
      declineButtonClasses={classes.btn}
      onDecline={handleDeclineCookie}
      onAccept={handleAcceptCookie}
      buttonText={data.approveButtonText ?? ""}
      declineButtonText={data.declineButtonText ?? ""}
    >
      <div>
        <Title as="h4">{data.title}</Title>
        <BodyText size={400}>{data.text}</BodyText>

        <BodyText
          size={400}
          className={classes.pointer}
          block={false}
          onClick={() => setSearchParams({ policy: "cookies" })}
        >
          {data.readMoreText}
        </BodyText>
      </div>
    </CookieConsent>
  );
};
