import React from "react";
import { HeroSection } from "../../graphql/schema";
import {
  tokens,
  makeStyles,
  shorthands,
  Text,
} from "@fluentui/react-components";
import { Title } from "../common/text/Title";
import { TextCard } from "../common/card/Card";
import Img from "../common/image/Img";
import { generateHTMLFromRichText } from "../../helper/markdownHelper";
type HeroProps = {
  data: HeroSection;
};
const useStyles = makeStyles({
  wrapper: {
    boxShadow: tokens.shadow8,
  },
  hero: {
    display: "none",
    "@media screen and (min-width: 600px)": {
      display: "block",
      backgroundSize: "cover",
      height: "655px",
    },
    "@media screen and (min-width: 1200px)": {
      height: "80vh", // Adjust the percentage as needed
    },
  },
  card: {
    ...shorthands.margin(0),
    width: "100%",
    boxShadow: "none",
    backgroundColor: "none",
    "@media screen and (min-width: 600px)": {
      backgroundColor: tokens.colorPaletteMarigoldBorderActive,
      boxShadow: tokens.shadow8,
      position: "absolute",
      top: "100px",
      width: "400px",
      marginLeft: 0,
      rowGap: 0,
    },
    "@media screen and (min-width: 800px)": {
      width: "700px",
      marginLeft: 0,
    },
  },
  maxWidth: {
    maxWidth: "1400px",
    ...shorthands.margin(0, "auto"),
    ...shorthands.padding(0, tokens.spacingHorizontalXXXL),
  },
  img: {
    "@media screen and (min-width: 600px)": {
      display: "none",
    },
  },
});
export const Hero = ({ data }: HeroProps) => {
  const classes = useStyles();
  return (
    <section className={classes.wrapper}>
      <Img
        className={classes.img}
        src={data.image?.data?.attributes?.url}
        alt={data.image?.data?.attributes?.alternativeText ?? ""}
      />
      <article
        className={classes.hero}
        style={{
          backgroundImage: `url("${data.image?.data?.attributes?.url}")`,
          backgroundPosition: "center",
        }}
      />
      <div className={classes.maxWidth}>
        <TextCard
          extraPadding={true}
          className={classes.card}
          header={
            <div>
              <Title as="h1">{data.title ?? ""}</Title>
              {data.subHeading && (
                <Text size={600} weight={"semibold"} as="h2">
                  {data.subHeading}
                </Text>
              )}
            </div>
          }
          description={generateHTMLFromRichText(data.description ?? [])}
        />
      </div>
    </section>
  );
};
